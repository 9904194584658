<template>
  <FadeTransition :duration="200">
    <div v-show="selected == 'instagram'">
      <hr class="my-5" />
      <h3>Conectarse a Instagram</h3>
      <p class="mb-5">
        Si utilizas otra plataforma para manejar los datos de tus clientes,
        desde aquí podrás integrarla.
      </p>
      <v-facebook-login
        :login-options="{
          scope:
            'instagram_manage_messages,pages_messaging,pages_manage_metadata,instagram_manage_comments,instagram_manage_insights,pages_show_list,instagram_basic,pages_read_engagement',
        }"
        style="margin: auto"
        @login="facebookLogged"
        app-id="280308108047238"
        class="mb-3 fb-login"
      ></v-facebook-login>
      <div class="my-5" v-show="connectedPages && connectedPages.length > 0">
        <h3 class="mb-4">Cuentas conectadas</h3>
        <span
          v-for="(connectedPage, idx) in connectedPages"
          :key="idx"
          class="btn-select static mr-3"
          >{{ connectedPage.credentials.name }}</span
        >
      </div>
      <hr class="mt-5 mb-3" />
    </div>
  </FadeTransition>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import VFacebookLogin from 'vue-facebook-login-component';
import { FadeTransition } from 'vue2-transitions';
import dashboard_api from '@/dashboard_api.js';
import environment from '@/environment';
import graphApiService from '@/services/api/graphApi.js';

export default {
  components: {
    FadeTransition,
    VFacebookLogin,
  },
  props: ['selected', 'bot_id', 'fields'],

  data() {
    return {
      connectedPages: [],
      edit: false,
      loading: false,
    };
  },

  created() {
    this.getInfoInstagram();
    this.check360Onboarding();
  },

  computed: {
    ...mapState(['user']),

    serchQuery() {
      return !!(
        this.$route.query.client ||
        this.$route.query.channels ||
        this.$route.query.revoked
      );
    },
  },

  watch: {
    selected() {
      this.getInfoInstagram();
      // console.log("this.currentWhatsapp->", this.currentWhatsapp);

      // if (this.currentWhatsapp.bot_id == this.bot_id) {
      //   this.edit = true;
      //   this.getCredentials();
      // } else {
      //   this.edit = false;
      // }
    },
    fields(val) {
      this.getInfoInstagram();
    },
    bot_id(val) {
      this.getInfoInstagram();
    },
  },
  methods: {
    async facebookLogged(e) {
      console.log('🚀 Aqui *** -> e', e);
      // solo para fines de test, se logea con USUARIO TEST
      const { accessToken, userID } = e.authResponse;
      graphApiService
        .generatePageAccessToken(userID, accessToken, this.bot_id, 'instagram')
        .then((el) => {
          // append connected pages
          this.$swal({
            icon: 'success',
            title: '¡Instagram integrado!',
            timer: 1000,
            showConfirmButton: false,
          });
          this.loading = false;
          this.$emit('closeView');
        })
        .catch((err) => console.log('err', err));
    },
    getCredentials() {
      this.phone = this.currentWhatsapp.phone;
      this.channel = this.currentWhatsapp.channel;
      this.bot_id = this.currentWhatsapp.bot_id;
      this.whatsapp.proveedor = this.currentWhatsapp.credentials.proveedor;
      this.whatsapp['D360-API-KEY'] =
        this.currentWhatsapp.credentials['D360-API-KEY'];
    },
    check360Onboarding() {},
    saveIntegration() {
      this.loading = true;

      if (!this.edit) {
        dashboard_api
          .post('/bot_credential/create', {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id,
          })
          .then((resp) => {
            this.$swal({
              icon: 'success',
              title: '¡WhatsApp integrado!',
              timer: 1000,
              showConfirmButton: false,
            });

            // contador de mensajes proximamente

            // dashboard_api
            //   .post('/payroll_message_counters', {
            //     payroll_message_counter: {
            //     income_messages: 0,
            //     outcome_messages: 0,
            //     bot_id: this.bot_id,
            //     provider_id: 1
            //   }
            //   .then((resp => {
            //     console.log('contador de mensajes', resp.data);
            //   }))
            // });
            this.loading = false;
            this.credentials = {};
            this.$emit('closeView');
          })
          .catch((error) => console.log('error->', error));
      } else {
        dashboard_api
          .put(`/bot_credential/update/${this.integrationId}`, {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id,
          })
          .then((resp) => {
            console.log('resp edit whatsapp', resp.data);

            this.$swal({
              icon: 'success',
              title: 'Integración modificada',
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.$emit('closeView');
          });
      }
      // seteando webhook 360dialog
      axios.post(environment.socket_url + '/whatsapp/360dialog/set-webhook', {
        apiKey: this.whatsapp['D360-API-KEY'],
      });
    },
    /**
     * @Description WhatsApp Business API 360Dialog Integrated Process
     */
    getInfoInstagram() {
      dashboard_api
        .get(`/bot_credential/get_by_bot_id/${this.bot_id}`)
        .then((resp) => {
          // filter by instagram
          resp.data = resp.data.filter(
            (item) => item.channel === 'instagram' && item.phone !== '569',
          );
          // console.log("respuesta whsap->", resp.data);
          if (resp.data.length > 0) {
            this.connectedPages = resp.data;
            this.$emit('getInsta', this.connectedPages);
          } else {
            this.$emit('dontWsp');
            this.phone = '569';
            this.whatsapp['D360-API-KEY'] = '';
            this.edit = false;
          }
        });
    },
  },
};
</script>

<style lang="scss">
.fb-login span {
  color: #ffff;
}
</style>
