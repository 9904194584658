import { render, staticRenderFns } from "./PlanDinamico.vue?vue&type=template&id=265c2aaa"
import script from "./PlanDinamico.vue?vue&type=script&lang=js"
export * from "./PlanDinamico.vue?vue&type=script&lang=js"
import style0 from "./PlanDinamico.vue?vue&type=style&index=0&id=265c2aaa&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports