<template>
  <FadeTransition :duration="200">
    <section class="bsale" v-show="selected == 'bsale'">
      <hr class="my-5" />
      <h3>Integrar Bsale</h3>
      <p class="mb-5">Si utilizas otra plataforma para manejar los datos de tus clientes, desde aquí podrás integrarla.</p>
      <div class="row mt-5 mb-4">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_market_id'">Completa el Id market de Bsale</p>
          <label v-else>Id market</label>
          <input
            :class="{border_error: error == 'error_market_id'}"
            placeholder="Ejem: 12901"
            type="number"
            v-model="bsale.id_market"
          />
        </div>
      </div>
      <p class="text_error" v-if="error == 'error_token'">Completa el token</p>
      <label v-else class="mb-2">Token</label>
      <label
        for="bsale-auth_token"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_token'}"
      >
        <input
          type="text"
          placeholder="Ingresa tu token"
          class="no_input"
          v-model="bsale.btoken"
          id="bsale-auth_token"
        />
      </label>
      <p class="text_error" v-if="error == 'error_bdomain' && 'error_empty'">Ingresa un dominio de bsale válido</p>
      <p class="text_error" v-else-if="error == 'error_empty'">Completa el dominio incluyendo http</p>
      <label v-else class="mb-2">Tu dominio</label>
      <label
        for="bsale-domain"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_bdomain' || error == 'error_empty'}"
      >
        <input
          type="text"
          placeholder="Ejem: https://tu-dominio.com"
          class="no_input"
          v-model="bsale.bdomain"
          id="bsale-domain"
        />
      </label>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading"
          >{{ !this.edit ? 'Guardar' : 'Guardar cambios' }}
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
          >Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
  ],
  data() {
    return {
      edit: false,
      loading: false,
      integrationId: 0,
      credentials: {},
      error: '',
      bsale: {
        id_market: '',
        btoken: '',
        bdomain: ''
      }
    };
  },

  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
    fields(val) {
      this.edit = false,
      this.loading = false;
      this.bsale.id_market = '';
      this.bsale.btoken = '';
      this.bsale.bdomain = '';
    }
  },
  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);

      this.bsale.id_market = currentCredentials.id_market;
      this.bsale.btoken = currentCredentials.btoken;
      this.bsale.bdomain = currentCredentials.bdomain;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      const { id_market, btoken, bdomain } = this.bsale;

      if (id_market.length == 0) {
        this.error = 'error_market_id';
        this.loading = false;
      } else if (btoken.length == 0) {
        this.error = 'error_token';
        this.loading = false;
      } else if (bdomain.length == 0) {
        this.error = 'error_empty';
        this.loading = false;
      } else if (!bdomain.includes("http")) {
        this.error = 'error_bdomain';
        this.loading = false;
      } else if (!this.edit) {
          dashboard_api
            .post("/user_credentials", {
              provider: this.selected,
              credentials: JSON.stringify(this.bsale),
              status,
              bot_id: this.bot_id
            },
          ).then(resp => {
            // console.log('resp', resp.data);
            this.$swal({
              icon: "success",
              title: "¡Bsale integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
        } else {
          dashboard_api
            .put(`/user_credentials/${this.integrationId}`, {
              credentials: JSON.stringify(this.bsale)
            }).then((resp => {
              // console.log('resp edit jump', resp.data);

              this.$swal({
                icon: "success",
                title: "Integración modificada",
                timer: 1000,
                showConfirmButton: false,
              });
              this.error = '';
              this.loading = false;
              this.$emit('closeView');
              this.$emit('getIntegrations');
            }));
        }
    }
  }
};
</script>
