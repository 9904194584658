<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'woocommerce'">
      <hr class="my-5" />
      <h3>Integrar Woocommerce</h3>
      <p class="mb-5">Si utilizas otra plataforma para manejar los datos de tus clientes, desde aquí podrás integrarla.</p>
      <div class="row mt-5 mb-4">
        <div class="col-lg-6 pl-0">
          <p class="text_error" v-if="error == 'error_wkey'">Completa el consumer key</p>
          <label v-else>Consumer key</label>
          <input
            :class="{border_error: error == 'error_wkey'}"
            placeholder="Ingresa tu consumer key"
            type="text"
            v-model="woocommerce.wkey"
          />
        </div>
        <div class="col-lg-6 pr-0">
          <p class="text_error" v-if="error == 'error_secret'">Completa el consumer secret</p>
          <label v-else>Consumer secret</label>
          <input
            :class="{border_error: error == 'error_secret'}"
            placeholder="Ingresa tu consumer secret"
            type="text"
            v-model="woocommerce.secret"
          />
        </div>
      </div>
      <p class="text_error" v-if="error == 'error_wdomain'">Completa el dominio</p>
      <label v-else class="mb-2">Tu dominio</label>
      <label
        for="woocommerce-domain"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_wdomain'}"
      >
        <input
          type="text"
          placeholder="Ejem: tu-dominio-woocommerce.com"
          class="no_input"
          id="woocommerce-domain"
          v-model="woocommerce.wdomain"
        />
      </label>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading"
          >{{ !edit ? 'Guardar' : 'Guardar cambios' }}
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
          >Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
  ],
  data() {
    return {
      edit: false,
      loading: false,
      credentials: {},
      error: '',
      woocommerce: {
        wkey: '',
        secret: '',
        wdomain: '',
      }
    };
  },
  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
    fields(val) {
      this.edit = false,
      this.woocommerce.wkey = '';
      this.woocommerce.secret = '';
      this.woocommerce.wdomain = '';
    }
  },

  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.woocommerce.wkey = currentCredentials.wkey;
      this.woocommerce.secret = currentCredentials.secret;
      this.woocommerce.wdomain = currentCredentials.wdomain;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      const { wkey, secret, wdomain } = this.woocommerce;

      if (wkey.length == 0) {
        this.error = 'error_wkey';
        this.loading = false;
      } else if (secret.length == 0) {
        this.error = 'error_secret';
        this.loading = false;
      } else if (wdomain.length == 0) {
        this.error = 'error_wdomain';
        this.loading = false;
      } else if (!this.edit) {
          dashboard_api
            .post("/user_credentials", {
              provider: this.selected,
              credentials: JSON.stringify(this.woocommerce),
              status,
              bot_id: this.bot_id,
            },
          ).then(resp => {
            console.log('Guardado', resp.data);

            this.$swal({
              icon: "success",
              title: "¡Woocommerce integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.credentials = {};
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
        } else {
          dashboard_api
            .put(`user_credentials/${this.integrationId}`, {
              credentials: JSON.stringify(this.woocommerce)
            }).then((resp => {
              console.log('resp edit jump', resp.data);

              this.$swal({
                icon: "success",
                title: "Integración modificada",
                timer: 1000,
                showConfirmButton: false,
              });
              this.loading = false;
              this.error = '';
              this.$emit('closeView');
              this.$emit('getIntegrations');
            }));
        }
    }
  }
};
</script>
