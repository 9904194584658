import axios from 'axios';
import environment from '@/environment.js';

export default {
  generatePageAccessToken(user_id, fb_exchange_token, botId, platform) {
    return axios.post(
      `${environment.url_env_bot}/graph_api/generate_page_access_token`,
      {
        user_id,
        fb_exchange_token,
        botId,
        platform,
      },
    );
  },
};
