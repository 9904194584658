<template>
  <section class="dashboard-planes my-4 pb-5">
    <div class="container">
      <SpinnerLoading v-if="!selected_bot" />
      <PlanDinamico v-else :key="selected_bot.id" />
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import PlanDinamico from "@/components/PlanDinamico";

export default {
  name: "dashboard-planes",

  components: {
    PlanDinamico
  },

  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      showLogin: false,
      showRegister: false
    };
  },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(["logged", "selected_bot"])
  }
};
</script>
