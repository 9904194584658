<template>
  <section class="widget-edit">
    <ValidationWhatsapp v-if="selected_bot.id" :bot="selected_bot.id" />
    <SpinnerLoading v-if="loading" />
    <template v-else>
      <div class="container mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-5 mb-5">
            <div class="card_box">
              <h2 class="title">Widget WhatsApp de {{ selected_bot.bot_name }}</h2>
              <p class="mb-5">
                Cada uno de tus chatbots incluye un widget de WhatsApp que puedes activar aquí.
              </p>
              <form class="form">
                <template v-if="selected_bot.id !== ''">
                  <div class="info_box mb-5">
                    <div class="row justify-content-between mb-0">
                      <label>Activar Widget</label>
                      <div class="field buefy">
                        <b-switch
                          :disabled="!selected_bot.activated"
                          v-model="widget_data.activate"
                        />
                      </div>
                    </div>
                    <p>Activa la ventana de WhatsApp en tu sitio.</p>
                  </div>
                  <SlideYUpTransition :duration="100">
                    <div v-show="widget_data.activate == true">
                      <label class="d-block"
                        >Asignar número<span class="bajada">(Código de área sin +)</span>
                      </label>
                      <input
                        type="text"
                        name="bot_name"
                        placeholder="569 1234 5678"
                        v-model="widget_data.number_agent"
                        maxlength="19"
                      />
                      <hr class="my-5" />
                      <label class="mb-4">Mostrar chatbot en:</label>
                      <div class="check__content">
                        <b-checkbox class="bajada mb-0" v-model="widget_data.desktop"
                          >Escritorio<img
                            class="desktop_icon"
                            src="/img/icons/widgetWhatsapp/3.1-desktop.svg"
                            alt="Escritorio"
                        /></b-checkbox>
                        <b-checkbox class="bajada mb-0" v-model="widget_data.mobile"
                          >Móvil<img
                            class="mobile_icon"
                            src="/img/icons/widgetWhatsapp/3.2-mobile.svg"
                            alt="movil"
                        /></b-checkbox>
                      </div>
                      <hr class="my-5" />
                      <div class="form-formulario mb-4">
                        <label>Configuración</label>
                        <p>
                          Configura los mensajes del Widget de WhatsApp.
                        </p>
                        <br />
                        <!-- <label>Mensaje Widget</label>
                        <div class="form__item">
                          <input
                            type="text"
                            name="bot_name"
                            :placeholder="widget_data.first_message"
                            v-model="widget_data.first_message"
                            maxlength="45"
                          />
                        </div> -->
                        <!-- <label>Nombre</label>
                        <div class="form__item">
                          <input
                            type="text"
                            name="name"
                            placeholder="Ingresa tu nombre"
                            v-model="widget_data.name_message"
                            maxlength="50"
                          />
                        </div>
                        <label>Número de WhatsApp</label>
                        <div class="form__item">
                          <input
                            type="text"
                            name="nro_whatsapp"
                            placeholder="+569"
                            v-model="widget_data.number_message"
                            maxlength="25"
                          />
                        </div>-->
                        <label>Mensaje para iniciar conversación</label>
                        <div class="form__item">
                          <input
                            type="text"
                            name="message"
                            placeholder="Escribe tu mensaje de inicio"
                            v-model="widget_data.message_widget"
                            maxlength="50"
                          />
                        </div> 
                      </div>
                      <hr class="my-5" />
                      <div class="form-apariencia mb-4">
                        <label>Apariencia inicial</label>
                        <p>
                          Selecciona como se verá la burbuja en tu sitio web.
                        </p>
                        <br />
                        <div class="row justify-content-between">
                          <div class="form__item">
                            <input type="radio" :value="true" v-model="widget_data.title" />
                            <img
                              src="/img/icons/widgetWhatsapp/1-Apariencia-barra.svg"
                              alt="whatsapp-burbuja"
                            />
                          </div>
                          <div class="form__item">
                            <input type="radio" :value="false" v-model="widget_data.title" />
                            <img
                              class="icono-wsp"
                              src="/img/icons/widgetWhatsapp/1-Apariencia-buble.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-mensaje mb-4">
                        <label>Titulo de barra</label>
                        <div class="form__item">
                          <input
                            type="text"
                            name="ini_message"
                            maxlength="17"
                            :placeholder="widget_data.message"
                            :disabled="!widget_data.title"
                            v-model="widget_data.message"
                          />
                        </div>
                      </div>
                      <div class="form-color mb-4">
                        <label>Color barra superior</label>
                        <div class="row justify-content-between align-items-center">
                          <div class="form__item">
                            <input type="radio" value="#075E54" v-model="widget_data.title_color" />
                            <div class="color-circle" style="background-color: #075e54" />
                          </div>
                          <div class="form__item">
                            <input type="radio" value="#128C7E" v-model="widget_data.title_color" />
                            <div class="color-circle" style="background-color: #128c7e" />
                          </div>
                          <div class="form__item">
                            <input type="radio" value="#25D366" v-model="widget_data.title_color" />
                            <div class="color-circle" style="background-color: #25d366" />
                          </div>
                          <div class="form__item">
                            <input type="radio" value="#00BFA5" v-model="widget_data.title_color" />
                            <div class="color-circle" style="background-color: #00bfa5" />
                          </div>
                        </div>
                      </div>
                      <hr class="my-5" />
                      <!-- <div class="form-Acciones mb-4">
                        <label>Acciones</label>
                        <p>Configura el comportamiento del widget</p>
                        <label>Asignar agente</label>
                        <div class="form__item">
                          <select alternative type="text" name="bot_name"

                          placeholder="Asignar agente">
                            <option value="">Seleccionar</option></select>
                        </div>
                      </div> -->
                    </div>
                  </SlideYUpTransition>
                  <div class="row my-5">
                    <button class="btn btn-base" @click.prevent="editwidget()" :disabled="loading">
                      Guardar
                    </button>
                  </div>
                </template>
              </form>
              <router-link class="btn-text" to="/dashboard"
                ><i class="fas fa-arrow-left mr-2" />Volver
              </router-link>
            </div>
          </div>
          <!-- segunda columna -->
          <div class="col-xl-5">
            <div class="card-chat">
              <WhatsApp
                :whatsapp__color__principal="widget_data.title_color"
                :titulo__mensaje="widget_data.first_message"
                :label__nombre="widget_data.name_message"
                :label__numero="widget_data.number_message"
                :label__mensaje="widget_data.message_widget"
                :bubble__title="widget_data.message"
                :bubble="widget_data.title"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from "vuex";
import dashboard_api from "@/dashboard_api.js";
import "../../assets/buefy_class.scss";
import WhatsApp from "@/components/WhatsApp";
import ValidationWhatsapp from "../Chatbot/components/validationWhatsapp";
import { SlideYUpTransition } from "vue2-transitions";

export default {
  components: {
    WhatsApp,
    ValidationWhatsapp,
    SlideYUpTransition
  },
  data() {
    return {
      show_content: false,
      desactivatedBot: {},
      isSwitched: false,
      isSwitchedCustom: "Yes",
      loading: false,
      widget: [],
      bot_data: [],
      // datos del widget
      color: "",
      widget_data: {
        id: "",
        activate: false,
        desktop: false,
        mobile: false,
        title_color: "",
        message: "¡Hablemos!",
        first_message: "¡Comencemos! Haz click para conversar.",
        name_message: "Nombre",
        number_message: "Número de WhatsApp",
        message_widget: "Hola! deseo más información al respecto.",
        number_agent: "",
        title: true
      }
    };
  },

  computed: {
    ...mapState(["user", "selected_bot"])
  },

  methods: {
    activeContent() {
      this.show_content = true;
    },
    async getInfoWidget(bot) {
      const self = this;
      console.log("bot->", bot);

      self.widget_data = (await dashboard_api.get(`/widget_whatsapp/widgetdata/${bot.id}`)).data;

      console.log("self.widget_data->", self.widget_data);
    },
    editwidget() {
      const self = this;
      this.loading = true;

      if (self.selected_bot.id === "") {
        self.$swal({
          icon: "error",
          title: "Debe seleccionar un bot para editar un Widget"
        });
      } else if (!self.widget_data.number_agent && self.widget_data.activate == true) {
        self.$swal({
          icon: "error",
          title: "El número asignado no puede estar vacio"
        });
        this.loading = false;

        // Limite para numero de whatsapp
        // else if(self.widget_data.number_agent.length < 9){
        //   self.$swal({
        //            icon: "error",
        //            title: 'El número asociado debe tener 9 dígitos incluyendo el 9'
        //           });
        // }
      } else {
        console.log("widget_data.number_agent->", self.widget_data.number_agent);

        dashboard_api
          .put(`/widget_whatsapp/editwidget/${self.selected_bot.id}`, {
            activate: self.widget_data.activate,
            desktop: self.widget_data.desktop,
            mobile: self.widget_data.mobile,
            first_message: self.widget_data.first_message,
            name_message: self.widget_data.name_message,
            number_message: self.widget_data.number_message,
            message: self.widget_data.message,
            message_widget: self.widget_data.message_widget,
            title_color: self.widget_data.title_color,
            title: self.widget_data.title,
            number_agent: self.widget_data.number_agent
          })
          .then(result => {
            self.$swal({
              icon: "success",
              title: "Widget editado",
              showConfirmButton: false,
              timer: 1000
            });
          })
          .catch(err => {
            console.log(err);
            self.$swal({
              icon: "error",
              title: "Hubo un error al editar"
            });
          });
        self.loading = false;
      }
    }
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getInfoWidget(val)
      }
    }
  },

  created() {
    if (this.selected_bot == null) {
      this.$swal({
        icon: "error",
        title: "Lo sentimos, pero debe de tener un bot para poder entrar a esta vista 🤖"
      });
      location.replace("/dashboard");
    }
  }
};
</script>

<style lang="scss" scoped>
.widget-edit {
  // padding: 6rem 0;

  .col-lg-5 {
    @media (max-width: 1279px) {
      justify-content: center;
      margin: auto;
      max-width: 100%;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  .bajada {
    color: #767676;
  }
  .card_box {
    padding: 0 2rem;

    @media (max-width: 480px) {
      padding: 0 0 1rem;
    }
    .title {
      margin-bottom: 1rem;
    }
  }
  .form {
    .check__content {
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .desktop_icon {
      width: 1.25rem;
      margin-left: 0.5rem;
    }
    .mobile_icon {
      width: 0.9rem;
      margin-left: 0.5rem;
    }
    &__item {
      margin: auto 0;
      align-items: center;
      display: flex;

      input[type="radio"] {
        margin: auto 0;
        margin-right: 0.5rem;
        align-items: center;
      }
      .color-circle {
        border-radius: 100%;
        border: 1px solid #cdcdcd;
        width: 40px;
        height: 40px;
        padding: 0;
        box-shadow: none;
        cursor: pointer;
      }
      .icono-whatsapp {
        width: 3.25rem;
      }
    }
    input {
      width: 100%;

      &[type="radio"] {
        width: auto;
      }
    }
    select {
      width: 100%;
    }
    &-apariencia {
      .icono-wsp {
        width: 3rem;
      }
    }
  }
  .card-chat {
    top: 50px;
    height: auto;
    padding-top: 0;
    position: -webkit-sticky;
    position: sticky;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 1279px) {
      position: relative;
    }
    @media (max-width: 480px) {
      box-shadow: none;
      padding: 1rem 0 0;
      border: none;
    }
  }
  .btn-base {
    margin: 0;
    width: 25%;

    @media (max-width: 1600px) {
      width: 45%;
    }
    @media (max-width: 1279px) {
      padding: 1rem 0;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
