<template>
  <section class="whatsapp">
    <!-- <div class="card">
      <div class="whatsapp__hero" :style="'background-color: '+whatsapp__color__principal+';'">
        <img class="whatsapp__hero-logo" src="/img/icons/widgetWhatsapp/2-wsp-logo.svg" alt="">
        <h3 class="whatsapp__hero-title">WhatsApp</h3>
      </div>
      <div class="whatsapp__body">
        <h2 class="whatsapp__body-title">{{ titulo__mensaje }}</h2>
        <h4 class="whatsapp__body-label">{{ label__nombre }}</h4>
        <input type="text" name=""
        class="input-group-alternative"
        placeholder="Ingresa tu nombre"/>
        <h4 class="whatsapp__body-label">{{ label__numero }}</h4>
        <input type="text" name=""
        class="input-group-alternative"
        placeholder="+569"/>
        <h4 class="whatsapp__body-label">{{ label__mensaje }}</h4>
        <input type="textarea" rows="3" name=""
        class="input-group-alternative"
        placeholder="Escribe tu mensaje"/>
        <button class="btn btn-whatsapp text-center">Iniciar</button>
        <div class="credits text-center">
          <img class="databot-iso" src="/img/icons/1-databot-icon.svg" alt="logo databot" />
          <label>Usamos Databot</label>
        </div>
      </div>
    </div> -->
    <br />
    <div class="bubble_wsp">
      <div
        class="bubble__bar"
        @click="open_window = !open_window"
        :style="'background-color: ' + whatsapp__color__principal + ';'"
        v-if="bubble"
      >
        <img
          class="bubble__bar-logo"
          src="/img/icons/widgetWhatsapp/2-wsp-logo.svg"
          alt="whatsapp"
        />
        <p class="bubble__bar-title">{{ bubble__title }}</p>
      </div>
      <div class="bubble__icon" @click="open_window = !open_window" v-else>
        <img
          class="bubble__bar-logo solo"
          src="/img/icons/widgetWhatsapp/2-wsp-logo.svg"
          alt="whatsapp"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Whatsapp',
  props: {
    whatsapp__color__principal: String,
    titulo__mensaje: String,
    label__nombre: String,
    label__numero: String,
    label__mensaje: String,
    bubble__title: String,
    bubble: Boolean,
  },
  data() {
    return {
      loading: false,
      widget_data: {
        id: '',
        message: '',
        activate: false,
        desktop: false,
        mobile: false,
        title_color: '',
        first_message: '',
        name_message: '',
        number_message: '',
        number_agent: '',
        message_widget: '',
        title: '',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.whatsapp {
  max-width: 361px;
  margin: auto;

  .card {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    padding: 0;
  }
  &__hero {
    align-items: center;
    display: inline-flex;
    padding: 0.5rem 1rem;
    width: 100%;
    height: 65px;
    border-radius: 1rem 1rem 0 0;
    background-color: #168c7e;

    &-logo {
      width: 2.5rem;
      margin-right: 0.5rem;
    }
    &-title {
      color: #ffffff;
      margin-bottom: 0;
    }
  }
  &__body {
    padding: 1rem 1.5rem;

    &-title {
      color: #168c7e;
      margin: 1rem auto 2rem;
    }
    &-label {
      font-size: 14px;
      color: #168c7e;
    }
    .btn-whatsapp {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      padding: 0.7rem 1rem;
      color: #fff;
      width: 100%;
      border-radius: 30px;
      border: none;
      background-color: #25d366;
      margin: 1rem auto;
    }
    .databot-iso {
      width: 0.8rem;
      margin-right: 0.4rem;
    }
    label {
      font-size: 12px;
      color: #b2b2b2;
    }
  }
  .bubble_wsp {
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;

    .bubble__bar {
      background-color: #128c7e;
      display: inline-flex;
      border-radius: 5rem;
      max-width: 65%;
      width: auto;
      padding: 0.5rem 1.25rem 0.5rem 0.5rem;
      align-items: center;

      &-logo {
        width: 2rem;
        margin-right: 0.5rem;
      }
      &-title {
        margin-bottom: 0;
        font-weight: 700;
        color: #ffffff;
      }
    }
    .solo {
      width: 5rem;
    }
  }
}
.whatsapp__body-title {
  text-align: center;
  margin: 1rem auto;
}
</style>
