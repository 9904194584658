<template>
  <section class="integraciones">
    <FadeTransition :duration="200">
      <SpinnerLoading v-if="loading" />
      <div v-else class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="mt-5">
              <h2>Integraciones de {{ selected_bot.bot_name }}</h2>
              <p>
                Selecciona el chatbot con el que quieres realizar las integraciones. En el caso de
                los ecommerce, solo puedes realizar una a la vez.
              </p>
              <div class="row my-5">
                <div v-for="other in others" :key="other.id">
                  <div v-show="other.show">
                    <label
                      :for="other.name"
                      :title="'Integrar ' + other.name"
                      :class="{
                        'card card-click': true,
                        'ig-card': other.name == 'instagram',
                        selected: selected == other.name
                      }"
                    >
                      <img
                        :class="{
                          icon_brand: true,
                          'ig-logo': other.name == 'instagram'
                        }"
                        :src="other.img_url"
                        :alt="other.name"
                      />
                    </label>
                    <input
                      type="radio"
                      class="not_here"
                      :id="other.name"
                      v-model="selected"
                      :value="other.name"
                    />
                  </div>
                </div>
                <div v-for="ecommerce in ecommerceFilter" :key="ecommerce.id">
                  <div v-show="ecommerce.show">
                    <label
                      :for="ecommerce.name"
                      :title="'Integrar ' + ecommerce.name"
                      :class="{
                        'card card-click': true,
                        selected: selected == ecommerce.name
                      }"
                    >
                      <img class="icon_brand" :src="ecommerce.img_url" :alt="ecommerce.name" />
                    </label>
                    <input
                      type="radio"
                      class="not_here"
                      :id="ecommerce.name"
                      v-model="selected"
                      :value="ecommerce.name"
                    />
                  </div>
                </div>
              </div>
              <EditDatabot :selected="selected" />
              <EditBsale
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditJumpseller
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditMagento
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditPrestashop
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditShopify
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditVtex
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditWhatsapp
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                @getWsp="wgtWhatsApp"
                @dontWsp="currentWsp = false"
                @closeView="selected = ''"
              />
              <EditInstagram
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                @getInsta="wgtInstaApp"
                @closeView="selected = ''"
              />
              <EditWoocommerce
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
            </div>
          </div>
          <div class="col-lg-4 justify-content-end mb-5">
            <h4 class="my-5">Integraciones activas</h4>
            <div class="scroll_box">
              <div
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'databot' }"
                @click="selected = 'databot'"
              >
                <div class="label-box">
                  <label class="mb-0">Databot</label>
                  <div class="d-flex align-items-center">
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div
                :key="currentWsp"
                v-show="currentWsp"
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'whatsapp' }"
                @click="selected = 'whatsapp'"
              >
                <div class="label-box">
                  <label class="mb-0">WhatsApp</label>
                  <div class="d-flex align-items-center">
                    <span @click="deleteWhatsappIntegration()" class="edit_hover error_hover mr-3"
                      >Eliminar</span
                    >
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div
                :key="currentInsta"
                v-show="currentInsta"
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'instagram' }"
                @click="selected = 'instagram'"
              >
                <div class="label-box">
                  <label class="mb-0">Instagram</label>
                  <div class="d-flex align-items-center">
                    <span
                      @click="deleteInstagramIntegration()"
                      class="edit_hover error_hover mr-3"
                      >Eliminar</span
                    >
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div :key="integrations.length" v-show="integrations.length > 0">
                <div
                  v-for="(integration, integrationIndex) in integrations"
                  :key="integration.id"
                  :class="{
                    'card buefy card-click card-left': true,
                    selected: selected == integration.provider
                  }"
                  @click="editIntegrations(integration.provider, integration)"
                >
                  <template v-if="bot_id == integration.bot_id">
                    <div class="label-box">
                      <label
                        class="capitalize mb-0"
                        @click="editIntegrations(integration.provider, integration)"
                        >{{ integration.provider }}</label
                      >
                      <div class="d-flex align-items-center">
                        <span
                          @click="deleteIntegration(integration, integrationIndex, integration.id)"
                          class="edit_hover error_hover mr-3"
                          >Eliminar</span
                        >
                        <b-switch
                          v-model="integration.status"
                          @input="changeStatus(integration.status, integration.id)"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="my-4" v-show="ecommerceWarning">
              <p>
                <i class="fas fa-info-circle destacado" />Ya tienes un ecommerce integrado. Debes
                eliminar el actual para poder cambiar.
              </p>
            </div>
          </div>
          <div class="col-lg-6 mb-5">
            <router-link class="btn-text" to="/dashboard"
              ><i class="fas fa-arrow-left mr-2" />Volver</router-link
            >
          </div>
          <div class="col-lg-4 mb-5" />
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import EditDatabot from "./components/EditDatabot";
import EditBsale from "./components/EditBsale";
import EditJumpseller from "./components/EditJumpseller";
import EditPrestashop from "./components/EditPrestashop";
import EditMagento from "./components/EditMagento";
import EditShopify from "./components/EditShopify";
import EditVtex from "./components/EditVtex";
import EditWhatsapp from "./components/EditWhatsapp";
import EditInstagram from './components/EditInstagram';
import EditWoocommerce from "./components/EditWoocommerce";
import dashboard_api from "@/dashboard_api.js";
import { FadeTransition } from "vue2-transitions";
import { mapState } from "vuex";
import "@/assets/buefy_class.scss";

export default {
  components: {
    FadeTransition,
    EditDatabot,
    EditBsale,
    EditJumpseller,
    EditPrestashop,
    EditMagento,
    EditShopify,
    EditVtex,
    EditWhatsapp,
    EditWoocommerce,
    EditInstagram
  },
  data() {
    return {
      ecommerceFilter: [],
      ecommerceWarning: false,
      wsapId: 0,
      igIds:[],
      currentWhatsapp: {},
      currentWsp: false,
      currentInsta: false,
      bot_id: null,
      loading: false,
      selected: "",
      providers: "",
      credentials: {},
      status: null,
      integrations: [],
      resetFields: false,
      others: [
        {
          name: "databot",
          show: true,
          img_url: "/img/logos/2-implementacion/databot-api.svg"
        },
        {
          name: "whatsapp",
          show: true,
          img_url: "/img/logos/2-implementacion/whatsapp.svg"
        },
        {
          name: 'instagram',
          show: true,
          img_url: '/img/logos/2-implementacion/instagram3.svg',
        },
        {
          name: "bsale",
          show: true,
          img_url: "/img/logos/2-implementacion/bsale-2.svg"
        }
      ],
      ecommerces: [
        {
          name: "jumpseller",
          show: true,
          img_url: "/img/logos/2-implementacion/jumpseller-2.svg"
        },

        {
          name: "magento",
          show: true,
          img_url: "/img/logos/2-implementacion/magento.svg"
        },

        {
          name: "prestashop",
          show: true,
          img_url: "/img/logos/2-implementacion/prestashop-2.svg"
        },

        {
          name: "shopify",
          show: true,
          img_url: "/img/logos/2-implementacion/shopify.svg"
        },

        {
          name: "vtex",
          show: true,
          img_url: "/img/logos/2-implementacion/vtex.svg"
        },

        {
          name: "woocommerce",
          show: true,
          img_url: "/img/logos/2-implementacion/woocommerce.svg"
        }
      ]
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.bot_id = this.selected_bot.id;
    this.getIntegrationsByBot(this.bot_id);
    this.reloadFilterEcommerce();
  },
  mounted() {
    this.validatePlan();
    this.validatePlatform();
  },

  watch: {
    selected(val) {
      this.providers = val;
    },

    selected_bot(bot) {
      this.bot_id = bot.id;
      this.getIntegrationsByBot(this.bot_id);
      this.reloadFilterEcommerce();
      this.selected = "";
    },
    plan_name(val) {
      this.validatePlan();
    }
  },
  computed: {
    ...mapState(["user", "selected_bot", "plan_name", "platform_name"])
  },

  methods: {
    validatePlan() {
      if (this.plan_name == "Plan Basic-web0" || this.plan_name == "Plan Starter-web1") {
        this.ecommerceFilter = this.ecommerces.filter(el => el.name != "vtex");
      } else {
        this.ecommerceFilter = this.ecommerces;
      }
    },
    validatePlatform() {
      if (this.platform_name == "shopify") {
        this.$router.push({ name: "dashboard" });
      }
    },

    reloadFilterEcommerce() {
      this.ecommerceWarning = false;

      for (let el of this.ecommerces) {
        el.show = true;
      }
    },
    changeStatus(integrationStatus, integrationId) {
      this.status = integrationStatus;

      dashboard_api
        .put(`/user_credentials/${integrationId}`, {
          status: integrationStatus
        })
        .then(resp => {
          // console.log('resp', resp.data);

          this.$swal({
            icon: "success",
            title: `Integración ${integrationStatus ? "Activa" : "Deshabilitada"}`,
            timer: 1000,
            showConfirmButton: false
          });
        })
        .catch(error => console.log("error->", error));
    },

    editIntegrations(provider, integration) {
      this.selected = integration.provider;
    },

    deleteIntegration(integration, integrationIndex, integrationId) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de eliminar esta integración",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(result => {
        if (result.isConfirmed) {
          // Borrar en front
          this.integrations.splice(integrationIndex, 1);
          // Borrar en back
          dashboard_api.delete(`/user_credentials/${integrationId}`);

          this.$swal({
            icon: "success",
            title: "Integración eliminada",
            timer: 1000,
            showConfirmButton: false
          });
          this.selected = "";
          this.resetFields = true;

          if (integration.provider != "bsale") {
            this.reloadFilterEcommerce();
          }
        }
      });
      this.resetFields = false;
    },

    deleteWhatsappIntegration() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de eliminar esta integración",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(result => {
        if (result.isConfirmed) {
          dashboard_api.delete(`/bot_credential/delete/${this.wsapId}`).then(result => {
            this.currentWsp = false;
            this.$swal({
              icon: "success",
              title: "Integración eliminada",
              timer: 1000,
              showConfirmButton: false
            });
            this.selected = "";
            this.resetFields = true;
          });
        }
        this.resetFields = false;
      });
    },
    deleteInstagramIntegration() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Estás a punto de eliminar esta integración',
        icon: 'warning',
        showCancelButton: true,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(result => {
        if (result.isConfirmed) {
          for (let item of this.igIds) {
            dashboard_api.delete(`/bot_credential/delete/${item.id}`).then(result => {
              console.log("Borrado: ", item.id);
            });
          }
          this.currentInsta = false;
          this.$swal({
            icon: 'success',
            title: 'Integración eliminada',
            timer: 1000,
            showConfirmButton: false,
          });
          this.selected = '';
          this.resetFields = true;
        }
        this.resetFields = false;
      });
    },
    wgtWhatsApp(currentWhatsapp) {
      this.wsapId = currentWhatsapp.id;
      this.currentWsp = true;
    },
    wgtInstaApp(currentInstagram) {
      this.igIds = currentInstagram;
      this.currentInsta = true;
    },
    async getIntegrationsByBot(bot) {
      try {
        this.loading = true;
        this.integrations = (
          await dashboard_api.get(`/user_credentials/search_for_bot/${bot}`)
        ).data;

        if (this.integrations && this.integrations.length > 0) {
          for (let item of this.integrations) {
            if (item.provider != "bsale") {
              for (let el of this.ecommerces) {
                // console.log("3->", el);
                if (el.name != item.provider) {
                  this.ecommerceWarning = true;
                  el.show = false;
                }
              }
            }
          }
        }
        if (this.integrations.length == 0) {
          this.resetFields = true;
        }
        this.loading = false;
      } catch (error) {
        console.log("error error", error);
        this.integrations = [];
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.integraciones {
  width: 100%;

  .card {
    padding: 1.25rem 2rem;
    margin-left: 0;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.buefy) {
      width: 160px;
      height: 65px;
    }
    @media (max-width: 1600px) {
      padding: 1.25rem 2.25rem;
    }
  }
  .bg_grey {
    cursor: pointer;

    &:hover,
    &:focus {
      border: 1px solid #2981ef !important;
    }
  }
  .scroll_box {
    max-height: 650px;
    padding: 0 5px 0.25rem 0 !important;
    overflow-y: scroll;
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
    @media (max-width: 1600px) {
      max-height: 510px;
    }
  }
  .edit_hover {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
  .scroll_box .card {
    padding: 1rem;
  }
  .card-click:hover div .edit_hover {
    opacity: 1;
  }
  .card-click:hover div label {
    cursor: pointer;
  }
  .text-state {
    padding: 0 0.75rem;
  }
  .ig-card {
    padding: 0 2rem !important;
  }
  .ig-logo {
    width: 90px;
  }
  // .card.card-left {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between !important;
  // }
}
</style>
