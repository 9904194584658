<template>
  <Modal
    class="modal-2"
    alto="500"
    ancho="700"
    :show="show"
    @close="$emit('close', (searchValue = ''))"
  >
    <template v-if="show" slot="content">
      <section class="enviar-plantilla">
        <div class="col-lg-12 pb-4">
          <h2 class="title-template">
            {{ dataResumen[number].title }} por Lead
            {{ filtered.length > 0 ? '- ' + filtered.length : '' }}
          </h2>
          <div class="row">
            <div class="col-lg-8 align-self-center">
              <p class="m-0">
                Mensaje {{ leads.message_name }} -
                {{ $moment(leads.date).format('DD/MM/YYYY') }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 align-self-center"></div>
            <div class="col-lg-6 mt-2">
              <input
                placeholder="Buscar nombre, número"
                class="input_search-sm"
                type="search"
                v-model.trim="searchValue"
              />
            </div>
          </div>
          <div class="info_table mx-3">
            <SpinnerLoading :showMessage="true" v-if="loading" />
            <template v-else>
              <div v-if="leadsFilter.length > 0" class="info_box scroll_box">
                <table class="table-resumen">
                  <tr
                    v-for="(resumen, index) in leadsFilter"
                    :key="leads.message_name + index"
                  >
                    <td class="text-limit">{{ resumen.name }}</td>
                    <td>{{ !resumen.phone ? '-' : resumen.phone }}</td>
                    <td>
                      <span
                        class="status-label"
                        :class="{
                          labelError:
                            resumen.status == 'error' ||
                            resumen.status == 'invalid_number' ||
                            resumen.status == 'failed',
                        }"
                      >
                        {{
                          resumen.status == 'sent'
                            ? 'En tránsito'
                            : resumen.status == 'delivered'
                            ? 'Recibido'
                            : resumen.status == 'read'
                            ? 'Abierto'
                            : 'Error'
                        }}
                      </span>
                    </td>
                    <td class="text_sm">
                      {{ $moment(resumen.date).format('DD/MM/YYYY hh:mm') }}
                    </td>
                  </tr>
                </table>
              </div>
              <div v-else class="info_box">
                <p class="text-center py-5">
                  No tienes leads en {{ dataResumen[number].title }}
                </p>
              </div>
            </template>
          </div>
        </div>
      </section>
    </template>
  </Modal>
</template>

<script>
import dashboard_api from '../dashboard_api.js';

export default {
  props: ['show', 'leads', 'number'],
  data() {
    return {
      loading: false,
      filtered: [],
      searchValue: '',
      selectTag: '',
      values: [],
      labelError: false,
      dataResumen: [
        {
          title: 'Total de mensajes',
          status: '',
        },
        {
          title: 'Mensajes en tránsito',
          status: 'sent',
        },
        {
          title: 'Mensajes recibidos',
          status: 'delivered',
        },
        {
          title: 'Mensajes abiertos',
          status: 'read',
        },
        {
          title: 'Mensajes fallidos',
          status: 'error',
        },
      ],
    };
  },
  created() {
    this.leadsDetails();
  },
  computed: {
    leadsFilter() {
      let leadsStatus = this.filtered;

      if (this.searchValue != '' && this.searchValue) {
        let hasNumber = /\d/;
        if (hasNumber.test(this.searchValue)) {
          leadsStatus = leadsStatus.filter(item => {
            return item.phone.toString().includes(this.searchValue);
          });
        } else {
          leadsStatus = leadsStatus.filter(item => {
            return item.name
              .toUpperCase()
              .includes(this.searchValue.toUpperCase());
          });
        }
      }
      return leadsStatus;
    },
  },
  methods: {
    // filterStatus(status) {
    //   return this.values.filter(e => e.status.includes(status));
    // },
    async leadsDetails() {
      this.loading = true;
      const filterMids = await this.leads.mids.filter(Boolean);

      if (filterMids.length > 0) {
        await dashboard_api
          .post(`/lead_payroll_messages/detail_leads`, {
            mids: filterMids,
          })
          .then(async resp => {
            this.values = await resp.data;
            this.filtered = await this.values.filter(e => {
              if (e.status == 'invalid_number' || e.status == 'failed') {
                e.status = 'error';
              }
              return e.status.includes(this.dataResumen[this.number].status);
            });
          })
          .then(resp => {
            this.loading = false;
            console.log('filtered ok--->', this.filtered);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.loading = false;
        console.log('no mids--->', this.filtered);
      }
    },
  },
};
</script>

<style lang="scss">
.enviar-plantilla {
  .bg-spinner {
    background-color: #fafafa;
    border: 1px solid #cdcdcd;
    border-radius: 1rem;
    position: relative;
    padding: 2rem 0;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.enviar-plantilla {
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .modal-content {
    width: 760px;
  }
  input {
    width: 100%;
    margin: 0 0 1rem;
  }
  select {
    width: 100%;
    margin: 0 0 1rem;
  }
  .contenedor {
    padding: 0 1rem;
  }
  .btn-base {
    width: 100%;
    margin: 0;
  }
  .div_input {
    font-size: 14px;
    line-height: 1.5rem;
    overflow-y: hidden;
    width: auto;
    height: 40px;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #767676;
    border-radius: 0;
    border-bottom: 1px solid #cdcdcd;
    padding: 0.75rem 0;
    transition: 0.5s;
    margin: 0;

    &:hover {
      color: #181818;
      border-bottom: 1px solid #333;
    }
    &:focus {
      height: auto !important;
      overflow-y: auto !important;
      animation: show-navbar-dropdown 0.25s ease forwards;
      color: #767676;
      border-bottom: 1px solid #333;
    }
  }
  .info_table {
    .info_box {
      padding: 0;
    }
  }
  .scroll_box {
    max-height: 200px;
    padding-left: 14px;
    padding-right: 10px;
    overflow-y: scroll;

    @media (max-width: 480px) {
      padding: 0.5rem;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      background: #e8e8e8;
      border-radius: 2rem;

      /* Cambiamos el fondo en hover */
      &:hover {
        background: #cdcdcd;
      }
      /* Cambiamos el fondo en active */
      &:active {
        background-color: #cdcdcd;
      }
    }
  }
  .text-state {
    margin: 0;
  }
  .fade_down {
    animation-name: first-fade-y-down;
    animation-duration: 0.5s;
  }
  .icon {
    display: flex;
    justify-content: center;
    margin: 0 auto 1rem;
  }
}
h2.title-template {
  padding: 0 15px 10px 15px;
  font-size: 20px;
  color: #181818;
  font-weight: 700;
}
h2.title-template.list-temp {
  margin-bottom: -10px;
  padding-top: 10px;
}
.p-4 {
  padding: 1rem 1.5rem 1.5rem 1.5rem !important;
}
.send-template {
  padding: 0.25rem 0.25rem;
}
.table-resumen {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}
td {
  padding: 10px;
  background-color: #fafafa;
  border-collapse: inherit;
}
span.status-label {
  margin: 0 5px;
  font-size: 12px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  padding: 2px 5px;
}
span.labelError {
  margin: 0 5px;
  font-size: 12px;
  border: 1px solid #ff6a6a;
  border-radius: 5px;
  padding: 2px 5px;
  color: #ff6a6a;
}
.text-nomessage {
  padding: 0 15px 10px 15px;
}
.text_sm {
  color: #767676;
}
.text-limit {
  max-width: 260px;
}
</style>
