<template>
  <section class="dashboard-checkout">
    <div class="container">
      <DashboardCheckout />
    </div>
  </section>
</template>

<script>
import DashboardCheckout from './Auth/components/DashboardCheckout';

export default {
  name: 'dashboard-checkout',

  components: {
    DashboardCheckout,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      showLogin: false,
      showRegister: false,
    };
  },
};
</script>
