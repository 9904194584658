<template>
  <FadeTransition :duration="200">
    <section class="jumpseller" v-show="selected == 'jumpseller'">
      <hr class="my-5" />
      <h3>Integrar Jumpseller</h3>
      <p class="mb-5">
        Si utilizas otra plataforma para manejar los datos de tus clientes,
        desde aquí podrás integrarla.
      </p>
      <label class="mb-2">Login</label>
      <label
        for="jumpseller-login"
        class="card card-click mb-5"
        :class="{ border_error: error == 'error_token' }"
      >
        <input
          type="text"
          placeholder="Ingresa tu credencial Login"
          class="no_input"
          v-model="jumpseller.login"
          id="jumpseller-login"
        />
      </label>
      <label class="mb-2">Auth token</label>
      <label
        for="jumpseller-auth_token"
        class="card card-click mb-5"
        :class="{ border_error: error == 'error_token' }"
      >
        <input
          type="text"
          placeholder="Ingresa tu token"
          class="no_input"
          v-model="jumpseller.authToken"
          id="jumpseller-auth_token"
        />
      </label>
      <p
        class="text_error"
        v-if="error == 'error_jdomain' && 'error_empty'"
      >
        Ingresa un dominio de jumpseller válido
      </p>
      <p class="text_error" v-else-if="error == 'error_empty'">
        Completa el dominio incluyendo http
      </p>
      <label v-else class="mb-2">Tu dominio</label>
      <label
        for="jumpseller-domain"
        class="card card-click mb-5"
        :class="{
          border_error: error == 'error_jdomain' || error == 'error_empty',
        }"
      >
        <input
          type="text"
          placeholder="Ejem: https://tu-dominio.com"
          class="no_input"
          v-model="jumpseller.jdomain"
          id="jumpseller-domain"
        />
      </label>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading"
        >
          {{ !this.edit ? 'Guardar' : 'Guardar cambios' }}
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
        >
          Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import dashboard_api from '@/dashboard_api.js';

export default {
  components: {
    FadeTransition,
  },
  props: ['selected', 'bot_id', 'fields', 'integrations'],
  data() {
    return {
      edit: false,
      loading: false,
      integrationId: 0,
      credentials: {},
      error: '',
      jumpseller: {
        login: '',
        authToken: '',
        jdomain: '',
      },
    };
  },

  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
    fields(val) {
      this.edit = false;
      this.loading = false;
      this.jumpseller.login = '';
      this.jumpseller.authToken = '';
      this.jumpseller.token = '';
      this.jumpseller.jdomain = '';
    },
  },
  methods: {
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);

      this.jumpseller.login = currentCredentials.login;
      this.jumpseller.authToken = currentCredentials.authToken;
      this.jumpseller.token = currentCredentials.token;
      this.jumpseller.jdomain = currentCredentials.jdomain;
    },
    saveIntegration() {
      let status = true;
      this.loading = true;

      const { login, authToken, token, jdomain } = this.jumpseller;

      if (login.length == 0) {
        this.error = 'error_login';
        this.loading = false;
      } else if (authToken.length == 0) {
        this.error = 'error_authToken';
        this.loading = false;
      } else if (jdomain.length == 0) {
        this.error = 'error_empty';
        this.loading = false;
      } else if (!jdomain.includes('http')) {
        this.error = 'error_jdomain';
        this.loading = false;
      } else if (!this.edit) {
        dashboard_api
          .post('/user_credentials', {
            provider: this.selected,
            credentials: JSON.stringify(this.jumpseller),
            status,
            bot_id: this.bot_id,
          })
          .then((resp) => {
            // console.log('resp', resp.data);
            this.$swal({
              icon: 'success',
              title: '¡Jumpseller integrado!',
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch((error) => console.log('error->', error));
      } else {
        dashboard_api
          .put(`/user_credentials/${this.integrationId}`, {
            credentials: JSON.stringify(this.jumpseller)
          })
          .then((resp) => {
            // console.log('resp edit jump', resp.data);

            this.$swal({
              icon: 'success',
              title: 'Integración modificada',
              timer: 1000,
              showConfirmButton: false,
            });
            this.error = '';
            this.loading = false;
            this.$emit('closeView');
            this.$emit('getIntegrations');
          });
      }
    },
  },
};
</script>
