var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mensajes-plantilla"},[_c('FadeTransition',{attrs:{"duration":200}},[(_vm.loading)?_c('SpinnerLoading'):_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-11"},[_c('div',{staticClass:"row my-4 align-items-center justify-content-between"},[_c('div',[_c('h2',{staticClass:"mb-0"},[_vm._v("Mensajes de plantilla")]),_c('p',[_vm._v(_vm._s(_vm.TemplateMessages || 0)+" Mensajes de plantilla enviados este mes.")])]),_c('div',{staticClass:"row aling-items-center"},[_c('span',{staticClass:"btn-filtro",on:{"click":function($event){_vm.showFilterModal = true}}},[_vm._v("Filtrar fechas")])])]),(_vm.searchMessages.length > 0)?[_c('section',{staticClass:"tabla__clientes buefy"},[_c('b-table',{key:_vm.tableKey,ref:"table",attrs:{"data":_vm.searchMessages,"hoverable":_vm.isHoverable,"selected":_vm.selectedRow,"detailed":"","custom-detail-row":"","detail-key":"message_name"},on:{"update:selected":function($event){_vm.selectedRow=$event}},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{staticClass:"hover-item",attrs:{"field":"name","label":"Nombre plantilla"}},[_vm._v(" "+_vm._s(props.row.message_name)+" "),_c('span',{staticClass:"total-send hover-item"},[_vm._v(_vm._s(props.row.sendTemplate))])]),_c('b-table-column',{attrs:{"field":"agent","label":"Enviado por"}},[_vm._v(" "+_vm._s(props.row.authorization)+" ")]),_c('b-table-column',{attrs:{"field":"cost","label":"Fecha"}},[_vm._v(" "+_vm._s(_vm.$moment(props.row.date).format('DD/MM/YYYY'))+" ")]),_c('b-table-column',{attrs:{"field":"messages","label":"Total mensajes"}},[_vm._v(" "+_vm._s(props.row.total_messages)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(props.row.showDetailIcon),expression:"props.row.showDetailIcon"}],class:{
                        'i-btn': true,
                        'i-eye': true,
                        'ml-2': true,
                        active: _vm.hoverAction == props.row.message,
                      },on:{"click":function($event){return _vm.showModalTags(props.row, 0)}}})]),_c('b-table-column',{attrs:{"field":"sendPersentage","label":"En tránsito"}},[(props.row.sended.total_sended_true > 0)?_c('span',[_vm._v(" "+_vm._s(props.row.sended.total_sended_true)+" ("+_vm._s(_vm.getPorcent( props.row.total_messages, props.row.sended.total_sended_true, ) + '%')+") "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(props.row.showDetailIcon),expression:"props.row.showDetailIcon"}],class:{
                          'i-btn': true,
                          'i-eye': true,
                          'ml-2': true,
                          active: _vm.hoverAction == props.row.message,
                        },on:{"click":function($event){return _vm.showModalTags(props.row, 1)}}})]):_c('span',[_vm._v(" - ")])]),_c('b-table-column',{attrs:{"field":"sendPersentage","label":"Recibidos"}},[(props.row.received.total_received_true > 0)?_c('span',[_vm._v(" "+_vm._s(props.row.received.total_received_true)+" ("+_vm._s(_vm.getPorcent( props.row.total_messages, props.row.received.total_received_true, ))+"%) "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(props.row.showDetailIcon),expression:"props.row.showDetailIcon"}],class:{
                          'i-btn': true,
                          'i-eye': true,
                          'ml-2': true,
                          active: _vm.hoverAction == props.row.message_name,
                        },on:{"click":function($event){return _vm.showModalTags(props.row, 2)}}})]):_c('span',[_vm._v(" - ")])]),_c('b-table-column',{attrs:{"field":"sendPersentage","label":"Abiertos"}},[(props.row.read.total_read_true > 0)?_c('span',[_vm._v(" "+_vm._s(props.row.read.total_read_true)+" ("+_vm._s(_vm.getPorcent( props.row.total_messages, props.row.read.total_read_true, ))+"%) "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(props.row.showDetailIcon),expression:"props.row.showDetailIcon"}],class:{
                          'i-btn': true,
                          'i-eye': true,
                          'ml-2': true,
                          active: _vm.hoverAction == props.row.message_name,
                        },on:{"click":function($event){return _vm.showModalTags(props.row, 3)}}})]):_c('span',[_vm._v(" - ")])]),_c('b-table-column',{attrs:{"field":"sendPersentage","label":"Fallidos"}},[(props.row.failed.total_with_failed > 0)?_c('span',[_vm._v(" "+_vm._s(props.row.failed.total_with_failed)+" ("+_vm._s(_vm.getPorcent( props.row.total_messages, props.row.failed.total_with_failed, ))+"%) "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(props.row.showDetailIcon),expression:"props.row.showDetailIcon"}],class:{
                          'i-btn': true,
                          'i-eye': true,
                          'ml-2': true,
                          active: _vm.hoverAction == props.row.message_name,
                        },on:{"click":function($event){return _vm.showModalTags(props.row, 4)}}})]):_c('span',[_vm._v(" - ")])])]}},{key:"detail",fn:function(props){return _vm._l((props.row.resumen),function(item,index){return _c('tr',{key:props.row.message_name + index,staticClass:"detail-template",on:{"mouseover":function($event){_vm.hoverAction = item.message_name + index},"mouseleave":function($event){_vm.hoverAction = false}}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(" "+_vm._s(item.message_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.authorization)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.date).format('DD/MM/YYYY'))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.total_messages)+" "),_c('span',{class:{
                          acciones: true,
                          active: _vm.hoverAction == item.message_name + index,
                        }},[_c('span',{class:{
                            'i-btn': true,
                            'i-eye': true,
                            'ml-2': true,
                            active: _vm.hoverAction == item.message_name + index,
                          },on:{"click":function($event){return _vm.showModalTags(item, 0)}}})])]),_c('td',[(item.sended.total_sended_true > 0)?_c('span',[_vm._v(" "+_vm._s(item.sended.total_sended_true)+" ("+_vm._s(_vm.getPorcent( item.total_messages, item.sended.total_sended_true, ) + '%')+") "),_c('span',{class:{
                            acciones: true,
                            active: _vm.hoverAction == item.message_name + index,
                          }},[_c('span',{class:{
                              'i-btn': true,
                              'i-eye': true,
                              'ml-2': true,
                              active:
                                _vm.hoverAction == item.message_name + index,
                            },on:{"click":function($event){return _vm.showModalTags(item, 1)}}})])]):_c('span',[_vm._v(" - ")])]),_c('td',[(item.received.total_received_true > 0)?_c('span',[_vm._v(" "+_vm._s(item.received.total_received_true)+" ("+_vm._s(_vm.getPorcent( item.total_messages, item.received.total_received_true, ))+"%) "),_c('span',{class:{
                            acciones: true,
                            active: _vm.hoverAction == item.message_name + index,
                          }},[_c('span',{class:{
                              'i-btn': true,
                              'i-eye': true,
                              'ml-2': true,
                              active:
                                _vm.hoverAction == item.message_name + index,
                            },on:{"click":function($event){return _vm.showModalTags(item, 2)}}})])]):_c('span',[_vm._v(" - ")])]),_c('td',[(item.read.total_read_true > 0)?_c('span',[_vm._v(" "+_vm._s(item.read.total_read_true)+" ("+_vm._s(_vm.getPorcent( item.total_messages, item.read.total_read_true, ))+"%) "),_c('span',{class:{
                            acciones: true,
                            active: _vm.hoverAction == item.message_name + index,
                          }},[_c('span',{class:{
                              'i-btn': true,
                              'i-eye': true,
                              'ml-2': true,
                              active:
                                _vm.hoverAction == item.message_name + index,
                            },on:{"click":function($event){return _vm.showModalTags(item, 3)}}})])]):_c('span',[_vm._v(" - ")])]),_c('td',[(item.failed.total_with_failed > 0)?_c('span',[_vm._v(" "+_vm._s(item.failed.total_with_failed)+" ("+_vm._s(_vm.getPorcent( item.total_messages, item.failed.total_with_failed, ))+"%) "),_c('span',{class:{
                            acciones: true,
                            active: _vm.hoverAction == item.message_name + index,
                          }},[_c('span',{class:{
                              'i-btn': true,
                              'i-eye': true,
                              'ml-2': true,
                              active:
                                _vm.hoverAction == item.message_name + index,
                            },on:{"click":function($event){return _vm.showModalTags(item, 4)}}})])]):_c('span',[_vm._v(" - ")])])])})}}],null,false,1961644217)})],1),_c('div',{staticClass:"row justify-content-between align-items-center my-3"},[_c('p',{staticClass:"text_sm mb-0"},[_vm._v(" Clientes en esta página: "+_vm._s(_vm.searchMessages.length)+" ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemsPerPage),expression:"itemsPerPage"}],staticClass:"select_text mr-0",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.itemsPerPage=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.getData('', '', 1)}]}},[_c('option',{attrs:{"value":"15"}},[_vm._v("15 por página")]),_c('option',{attrs:{"value":"30"}},[_vm._v("30 por página")]),_c('option',{attrs:{"value":"50"}},[_vm._v("50 por página")])])])]:[_c('div',{staticClass:"info_box"},[_c('img',{staticClass:"icon",attrs:{"src":"/img/icons/15-not_found.svg","alt":"databot"}}),_c('p',{staticClass:"text-center"},[_vm._v("No se encontraron mensajes")])])],_c('div',{staticClass:"row justify-content-between my-3"},[(_vm.searchMessages.length > 0)?[(_vm.total_pages > 1)?_c('div',{staticClass:"pagination"},[(_vm.actual_page > 1)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = 1;
                    _vm.getData('', '', 1);}}},[_vm._v(" 1 ")]):_vm._e(),(_vm.actual_page != 1)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = _vm.actual_page - 1;
                    _vm.getData('', '', _vm.actual_page);}}},[_c('i',{staticClass:"fas fa-caret-left"})]):_vm._e(),(_vm.actual_page > 2)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = _vm.actual_page - 1;
                    _vm.getData('', '', _vm.actual_page);}}},[_vm._v(" "+_vm._s(_vm.actual_page - 1)+" ")]):_vm._e(),_c('button',{staticClass:"btn-pagination active_page",on:{"click":function($event){_vm.actual_page = _vm.actual_page;
                    _vm.getData('', '', _vm.actual_page);}}},[_vm._v(" "+_vm._s(_vm.actual_page)+" ")]),(_vm.total_pages != _vm.total_pages)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = _vm.actual_page + 1;
                    _vm.getData('', '', _vm.actual_page);}}},[_vm._v(" "+_vm._s(_vm.actual_page + 1)+" ")]):_vm._e(),(_vm.actual_page != _vm.total_pages)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = _vm.actual_page + 1;
                    _vm.getData('', '', _vm.actual_page);}}},[_c('i',{staticClass:"fas fa-caret-right"})]):_vm._e(),(_vm.actual_page != _vm.total_pages)?_c('button',{staticClass:"btn-pagination",on:{"click":function($event){_vm.actual_page = _vm.total_pages;
                    _vm.getData('', '', _vm.actual_page);}}},[_vm._v(" "+_vm._s(_vm.total_pages)+" ")]):_vm._e()]):_vm._e()]:_vm._e()],2)],2)])])],1),_c('TemplateResumen',{key:_vm.idKey + _vm.number,attrs:{"show":_vm.showPlantillas,"number":_vm.number,"leads":_vm.leadsTemplateResumen},on:{"close":function($event){_vm.showPlantillas = false}}}),_c('DateFilterModal',{attrs:{"showFilterModal":_vm.showFilterModal},on:{"closeModal":function($event){_vm.showFilterModal = false},"filterDate":_vm.filterDatePush}}),_c('UnAnswered',{attrs:{"showUnAnswered":_vm.showUnAnswered,"un_answered":_vm.un_answered},on:{"closeModal":function($event){_vm.showUnAnswered = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }