<template>
  <FadeTransition :duration="200">
    <div v-show="selected == 'whatsapp'">
      <hr class="my-5" />
      <h3>Integrar WhatsApp API</h3>
      <p class="mb-5">
        Si utilizas otra plataforma para manejar los datos de tus clientes, desde aquí podrás
        integrarla.
      </p>
      <button
        class="btn btn-base second"
        v-if="!serchQuery && whatsapp['D360-API-KEY'] == ''"
        @click="startIntegratedOnboarding360"
      >
        Empezar proceso
      </button>
      <template v-else>
        <div class="row mt-5 mb-4">
          <div class="col-lg-6 pl-0">
            <label>Número</label>
            <input type="number" placeholder="569 1234 5678" v-model="phone" />
          </div>
          <div class="col-lg-6 pr-0">
            <label>Proveedor</label>
            <input placeholder="360 Dialog" type="text" disabled v-model="whatsapp.proveedor" />
          </div>
        </div>
        <label class="mb-2">Api key</label>
        <label for="whatsapp_api_key" class="card card-click mb-5">
          <input
            type="text"
            placeholder="Ingresa tu Api key"
            class="no_input"
            id="whatsapp_api_key"
            v-model="whatsapp['D360-API-KEY']"
          />
        </label>
        <div class="row justify-content-between">
          <button @click="saveIntegration()" class="btn btn-base md mx-0" :disabled="loading">
            {{ !this.edit ? "Guardar" : "Guardar cambios" }}
          </button>
          <button class="btn btn-base outline md mx-0" @click="$emit('closeView')">
            Cancelar
          </button>
        </div>
      </template>
      <hr class="mt-5 mb-3" />
    </div>
  </FadeTransition>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";
import environment from "@/environment";

export default {
  components: {
    FadeTransition
  },
  props: ["selected", "bot_id", "fields"],

  data() {
    return {
      edit: false,
      loading: false,
      currentWhatsapp: {},
      phone: "569",
      channel: "Whatsapp",
      whatsapp: {
        proveedor: "360DIALOG",
        "D360-API-KEY": "",
        host_type: 'CLOUD',
      }
    };
  },

  created() {
    this.getInfoWhatsapp();
    this.check360Onboarding();
  },

  computed: {
    ...mapState(["user"]),

    serchQuery() {
      return !!(
        this.$route.query.client ||
        this.$route.query.channels ||
        this.$route.query.revoked
      );
    }
  },

  watch: {
    selected() {
      this.getInfoWhatsapp();
      // console.log("this.currentWhatsapp->", this.currentWhatsapp);

      if (this.currentWhatsapp.bot_id == this.bot_id) {
        this.edit = true;
        this.getCredentials();
      } else {
        this.edit = false;
      }
    },
    fields(val) {
      this.getInfoWhatsapp();
    },
    bot_id(val) {
      this.getInfoWhatsapp();
    }
  },

  methods: {
    getCredentials() {
      this.phone = this.currentWhatsapp.phone;
      this.channel = this.currentWhatsapp.channel;
      this.bot_id = this.currentWhatsapp.bot_id;
      this.whatsapp.proveedor = this.currentWhatsapp.credentials.proveedor;
      this.whatsapp["D360-API-KEY"] = this.currentWhatsapp.credentials["D360-API-KEY"];
    },
    check360Onboarding() {
      const { client, channels, revoked } = this.$route.query;
      // console.log("🚀 Aqui *** -> client,channels,revoked", {
      //   client,
      //   channels,
      //   revoked
      // });
      if (client && channels) {
        // generate api key
        this.sendDataTo360WebhookPartner({
          client,
          channels,
          revoked,
          from: "Query parameters"
        });
      }
      // listener
      window.addEventListener(
        "message",
        event => {
          const { data } = event;
          const queryString = data;
          // console.log("Datos Listener: ", queryString);
          // ?client=oaY9LLfUCL&channels=[y9MiLoCH]
          // make request to backend bot to generate api key
          if (queryString.client && queryString.channels) {
            this.sendDataTo360WebhookPartner({
              ...queryString,
              from: "Listener"
            });
          }
        },
        false
      );
    },
    saveIntegration() {
      this.loading = true;

      if (!this.edit) {
        dashboard_api
          .post("/bot_credential/create", {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id
          })
          .then(resp => {
            this.$swal({
              icon: "success",
              title: "¡WhatsApp integrado!",
              timer: 1000,
              showConfirmButton: false
            });

            // contador de mensajes proximamente

            // dashboard_api
            //   .post('/payroll_message_counters', {
            //     payroll_message_counter: {
            //     income_messages: 0,
            //     outcome_messages: 0,
            //     bot_id: this.bot_id,
            //     provider_id: 1
            //   }
            //   .then((resp => {
            //     console.log('contador de mensajes', resp.data);
            //   }))
            // });
            this.loading = false;
            this.credentials = {};
            this.$emit("closeView");
          })
          .catch(error => console.log("error->", error));
      } else {
        dashboard_api
          .put(`/bot_credential/update/${this.integrationId}`, {
            phone: this.phone,
            channel: this.channel,
            credentials: this.whatsapp,
            bot_id: this.bot_id
          })
          .then(resp => {
            console.log("resp edit whatsapp", resp.data);

            this.$swal({
              icon: "success",
              title: "Integración modificada",
              timer: 1000,
              showConfirmButton: false
            });
            this.loading = false;
            this.$emit("closeView");
          });
      }
      // seteando webhook 360dialog
      axios.post(environment.socket_url + "/whatsapp/360dialog/set-webhook", {
        apiKey: this.whatsapp["D360-API-KEY"]
      });
    },
    /**
     * @Description WhatsApp Business API 360Dialog Integrated Process
     */
    async sendDataTo360WebhookPartner(queryString) {
      const resp = await axios.post(
        environment.socket_url + "/whatsapp/360dialog/webhook_partner_data",
        {
          bot_id: this.bot_id,
          ...queryString
        }
      );
      const data = resp.data;
      this.whatsapp["D360-API-KEY"] = data.api_key;
      this.phone = data.phone;
      // notificando a cliente
      this.$swal({
        icon: "success",
        title: "¡WhatsApp integrado!",
        timer: 1000,
        showConfirmButton: false
      });
    },
    getInfoWhatsapp() {
      dashboard_api.get(`/bot_credential/get_by_bot_id/${this.bot_id}`).then(resp => {
        // console.log("respuesta whsap->", resp.data);
        if (resp.data.length > 0) {
          this.currentWhatsapp = resp.data[0];
          // console.log("🚀 Aqui *** -> this.currentWhatsapp ", this.currentWhatsapp);
          this.integrationId = resp.data[0].id;
          this.$emit("getWsp", this.currentWhatsapp);
        } else {
          this.$emit("dontWsp");
          this.phone = "569";
          this.whatsapp["D360-API-KEY"] = "";
          this.edit = false;
        }
      });
    },
    startIntegratedOnboarding360() {
      const windowFeatures = "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";
      open(
        "https://hub.360dialog.com/dashboard/app/pD8nLIPA/permissions",
        "integratedOnboardingWindow",
        windowFeatures
      );
    }
  }
};
</script>
